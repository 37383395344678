import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './style/BottomNav.css';
import { IoFastFoodOutline } from 'react-icons/io5';
import { AuthContext } from '../../context/AuthContext';

const BottomNav = () => {
  const [showNav, setShowNav] = useState(false);
  const { isLogged, } = useContext(AuthContext);
  const token = localStorage.getItem('History_id');
  const navigate=useNavigate();
  const handleScroll = () => {
    if (window.scrollY > 40 && window.innerWidth <= 991) {
      setShowNav(true);
    } else {
      setShowNav(false);
    }
  };

  const handleResize = () => {
    if (window.innerWidth <= 991) {
      setShowNav(window.scrollY > 40);
    } else {
      setShowNav(false);
    }
  };
  const HandleSignOut = async () => {
    try {
        await signOut(auth);
        navigate("/");
        message.success("Signed out successfully")
        console.log("User signed out successfully.");
    } catch (error) {
        message.error("Error signing out! Try again")
        console.error("Error signing out: ", error);
    }

}
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    showNav && (
      <div id='myBttomFix' className="fixed-bottom bg-light p-3 shadow d-flex justify-content-around">
        <div onClick={()=>navigate('/')} style={{color: location.pathname === '/' ? '#FEA116' : 'black'}} className="text-center">
          <i className="bi bi-house-door"></i>
          <p className="mb-0">Home</p>
        </div>
        <div onClick={()=>navigate('/menu/1')} className="text-center" style={{color: location.pathname === '/menu/1' ? '#FEA116' : '#535151'}}>
          <IoFastFoodOutline style={{fontSize: "33px"}}/>
          {/* <i className="bi bi-list"></i> */}
          <p style={{marginTop: "7px"}} className="mb-0">Menu</p>
        </div>
        <div  onClick={()=>navigate('/cart')} className="text-center" style={{color: location.pathname === '/cart' ? '#FEA116' : 'black'}}>
          <i className="bi bi-cart"></i>
          <p className="mb-0">Cart</p>
        </div>
        <div   onClick={()=>navigate('/about')} className="text-center" style={{color: location.pathname === '/about' ? '#FEA116' : 'black'}}>
          <i className="bi bi-info-circle"></i>
          <p className="mb-0">About</p>
        </div>
        {

isLogged ? (
    <>
        {location.pathname === '/user-profile' ? (<>
          <div  onClick={HandleSignOut} className="text-center" style={{color: location.pathname === '/login' ? '#FEA116' : 'black'}}>
          <i class="bi bi-box-arrow-left"></i>
          <p className="mb-0">LogOut</p>
        </div>
        </>) : (<>
          <div  onClick={()=>navigate('/user-profile')} className="text-center" style={{color: location.pathname === '/login' ? '#FEA116' : 'black'}}>
          <i className="bi bi-person"></i>
          <p className="mb-0">Profile</p>
        </div>
        </>)}
    </>) : (
    <>
        {token ? (<>
          <div  onClick={()=>navigate('/user-profile')} className="text-center" style={{color: location.pathname === '/login' ? '#FEA116' : 'black'}}>
          <i className="bi bi-person"></i>
          <p className="mb-0">Profile</p>
        </div>
        </>) : (<>
          <div  onClick={()=>navigate('/login')} className="text-center" style={{color: location.pathname === '/login' ? '#FEA116' : 'black'}}>
          <i className="bi bi-person"></i>
          <p className="mb-0">Login</p>
        </div>
        </>)}
    </>


)
}
        
      </div>
    )
  );
};

export default BottomNav;
