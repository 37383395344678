import React, { useEffect } from 'react';
import { LoadingOutlined} from "@ant-design/icons";
function Splash() {
  // useEffect(() => {
  //   AOS.init({ duration: 2000 }); // Initialize AOS with a duration of 2000ms
  // }, []);

  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: animationData,
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice',
  //   },
  // };

  return (
    <div style={styles.container}>
      <div data-aos="fade-up" style={styles.animationContainer}><LoadingOutlined style={{color:"#5EAE53"}}/>
        {/* <Lottie options={defaultOptions} height={300} width={300} /> */}
        <h2 style={styles.text} data-aos="fade-up" data-aos-delay="100">
          Loading...
        </h2>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f0f0f0', // Light grey background
  },
  animationContainer: {
    textAlign: 'center',
  },
  text: {
    fontSize: '2rem',
    color: '#F49B3F', // Vibrant color for the text
    marginTop: '20px',
    fontWeight: 'bold',
    animation: 'bounce 2s infinite', // Add bounce animation
  },
};

export default Splash;
