import React, { useState, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import './CookieConsentBanner.css';  // Custom CSS for styling

const CookieConsentBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Check if user has already made a cookie consent decision
    const cookieConsent = localStorage.getItem("cookieConsent");
    if (!cookieConsent) {
      setShowBanner(true); // Show banner if no decision was made
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setShowBanner(false); // Hide banner on accept
  };

  const handleDecline = () => {
    localStorage.setItem("cookieConsent", "declined");
    setShowBanner(false); // Hide banner on decline
  };

  if (!showBanner) return null; // Don't show the banner if user has already made a choice

  return (
    <div className="cookie-banner">
      <Container id="MainBanner" >
        <div className="cookie-text">
          <p>
            We use cookies to ensure that we give you the best experience on our website. 
            By continuing, you agree to our <Link to={"/privacy-policy"}>Privacy Policy</Link>.
          </p>
        </div>
        <div className="cookie-buttons">
          <Button variant="primary" onClick={handleAccept}>
            Accept
          </Button>
          <Button variant="secondary" onClick={handleDecline}>
            Decline
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default CookieConsentBanner;
