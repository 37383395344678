import { message } from 'antd';
import React, { createContext, useState, useEffect } from 'react';

// Create the Cart Context
export const CartContext = createContext();

function CartProvider({ children }) {
  const [cartItems, setCartItems] = useState([]);

  // Load cart items from localStorage when the component mounts
  useEffect(() => {
    getCart();
  }, []);
  const getCart=()=>{
    const savedCartItems = localStorage.getItem('cartItems');
    if (savedCartItems) {
      setCartItems(JSON.parse(savedCartItems));
    }
  }
  // Function to add item to cart
  const addToCart = (product) => {
    // console.log(product);
    try {
      const existingProduct = cartItems.find(item => item.id === product.id);
      if (existingProduct) {
        const updatedCart = cartItems.map(item => 
          item.id === product.id 
            ? { ...item, quantity: item.quantity + product.quantity }
            : item
        );
        console.log(updatedCart);
        setCartItems(updatedCart);
        localStorage.setItem('cartItems', JSON.stringify(updatedCart));
        localStorage.setItem('recentItems', JSON.stringify({TrackOrder:product.TrackOrder,Slected:product.Slected,day:product.day,time:product.time}));
        getCart();
      } else {
        const updatedCart = [...cartItems, product];
        setCartItems(updatedCart);
        localStorage.setItem('cartItems', JSON.stringify(updatedCart));
        localStorage.setItem('recentItems', JSON.stringify({TrackOrder:product.TrackOrder,Slected:product.Slected,day:product.day,time:product.time}));
      }
      message.success("Product added successfully!");getCart();
    } catch (error) {
      message.error("An error occurred!");
      console.log(error);
    }
  };
  
  
  

  // Function to remove item from cart (optional)
  const removeFromCart = (id) => {
    try {
      const updatedCart = cartItems.filter(item => item.id !== id);
      setCartItems(updatedCart);
      localStorage.setItem('cartItems', JSON.stringify(updatedCart));
      message.success("Product Removed Successfully");
    } catch (error) {
      console.log(error);message.error("Sorry Some Error Occured !");
    }
   
  };

  return (
    <CartContext.Provider value={{ cartItems, addToCart, removeFromCart ,getCart}}>
      {children}
    </CartContext.Provider>
  );
}

export default CartProvider;
