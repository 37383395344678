import React, { createContext, useContext, useState, useEffect } from 'react';
import { collection, doc, getDocs, getDoc } from 'firebase/firestore';
import { db } from '../Config';

// Create the context
export const ReviewsContext = createContext();
export const CategoriesContext = createContext();

// Export hooks to use the contexts in other components
export const useReviews = () => useContext(ReviewsContext);
export const useCategories = () => useContext(CategoriesContext);

// Separate function to fetch all reviews from Firestore
const getAllReviews = async () => {
  const reviewsCollection = collection(db, 'reviews');
  const reviewsSnapshot = await getDocs(reviewsCollection);
  const reviewsList = reviewsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  return reviewsList;
};
const getAllTeamMembers = async () => {
  const teamCollection = collection(db, 'team');
  const teamSnapshot = await getDocs(teamCollection);
  const teamList = teamSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  return teamList;
};

// Separate function to fetch all categories from Firestore
const getAllCategories = async () => {
  const categoriesCollection = collection(db, 'categories');
  const categoriesSnapshot = await getDocs(categoriesCollection);
  const categoriesList = categoriesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  return categoriesList;
};
const getAllProducts = async () => {
  const ProductCollection = collection(db, 'Products');
  const ProductsSnapshot = await getDocs(ProductCollection);
  const ProductsList = ProductsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  return ProductsList;
};
const getAllOffers = async () => {
  const Offers= collection(db, 'special_offers');
  const OffersSnapshot = await getDocs(Offers);
  const OffersList = OffersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  return OffersList;
};
const getAllOrders = async () => {
  const Offers= collection(db, 'orders');
  const OffersSnapshot = await getDocs(Offers);
  const OrdersList = OffersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  return OrdersList;
};    const getAllJobs = async () => {
  const Offers= collection(db, 'Jobs');
  const OffersSnapshot = await getDocs(Offers);
  const OrdersList = OffersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  return OrdersList;
};
// Provider component that fetches and provides reviews and categories
export const GetAllDataProvider = ({ children }) => {
  // State for reviews
  const [reviews, setReviews] = useState([]);
  const [loadingReviews, setLoadingReviews] = useState(true);

  // State for categories
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);

  // Fetch reviews
  const fetchReviews = async () => {
    const allReviews = await getAllReviews();
    setReviews(allReviews);
    setLoadingReviews(false);
  };

  // Fetch categories
  const fetchCategories = async () => {
    const allCategories = await getAllCategories();
  
    // Sort categories alphabetically by `category_name` and handle potential undefined values
    const sortedCategories = allCategories.sort((a, b) => {
      const nameA = a.name ? a.name.toLowerCase() : '';
      const nameB = b.name ? b.name.toLowerCase() : '';
      return nameA.localeCompare(nameB);
    });
  
    setCategories(sortedCategories);
    setLoadingCategories(false);
  };
  


  const [Offers, setOffers] = useState([])
  const getOffers = async () => {
    const allOffers= await getAllOffers();
    setOffers(allOffers);
    setLoadingCategories(false);
  };
  
  //Fetch Products
  const [products, setproducts] = useState([])
  const fetchProducts = async () => {
    const allProducts = await getAllProducts();
    setproducts(allProducts);
    setLoadingCategories(false);
  };
  
  const [team, setteams] = useState([])
  const fetchTeam= async () => {
    const allProducts = await getAllTeamMembers();
    setteams(allProducts);
    setLoadingCategories(false);
  };
  const [settings, setsettings] = useState({})
  const fetchSettings = async () => {
    const settingsRef = doc(db, 'settings', 'website');
    const docSnap = await getDoc(settingsRef);
    if (docSnap.exists()) {
      setsettings(docSnap.data());
    }
  };
  const [openingTime, setOpeningTime] = useState(null)
  const fetchOpeningTimes = async () => {
    const docRef = doc(db, 'settings', 'openingTimes');
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setOpeningTime(docSnap.data());
      // setDocExists(true);
    }
  };
  const [orderLoading, setOrderLoading] = useState(true)
  const [orders, setOrders] = useState([]);
  const fetchOrders= async () => {
    const allProducts = await getAllOrders();
    setOrders(allProducts);
    setOrderLoading(false);
  };
  const [jobs, setJobs] = useState([]);
  const fetchJobs= async () => {
    const allProducts = await getAllJobs();
    setJobs(allProducts);
    setOrderLoading(false);
  };
  useEffect(() => {
    fetchReviews();fetchSettings();fetchOpeningTimes();fetchOrders();
    fetchCategories();fetchProducts();getOffers();fetchTeam();fetchJobs();
  }, []);

  return (
    <ReviewsContext.Provider value={{ reviews, loading: loadingReviews, fetchReviews }}>
      <CategoriesContext.Provider value={{jobs,fetchOrders,orderLoading,orders, openingTime,settings, team,fetchTeam,loadingCategories, products, categories,Offers, loading: loadingCategories, fetchCategories }}>
        {children}
      </CategoriesContext.Provider>
    </ReviewsContext.Provider>
  );
};

export default GetAllDataProvider;
