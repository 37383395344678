// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyA_YsiE6ygaSftu8TzPT_05h_1l4iKtJNs",
  authDomain: "kebabilicious-d27ae.firebaseapp.com",
  projectId: "kebabilicious-d27ae",
  storageBucket: "kebabilicious-d27ae.appspot.com",
  messagingSenderId: "721091241104",
  appId: "1:721091241104:web:9e82b1ee0e80e6bbb22415",
  measurementId: "G-JGM1DLND6E"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore();
export { app, auth };
