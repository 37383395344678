import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import pMinDelay from "p-min-delay";
import Splash from './component/Loader/Splash';
import loadable from './component/ShareComponent/Loadable';
import BottomNav from './component/ShareComponent/BottomNav';
import './css/bootstrap.min.css'; import './css/style.css'
import CartProvider from './context/CartContext';
import { AuthProvider } from './context/AuthContext';
import CookieConsentModal from './cookies/CookieConsentModal';
import BirthdayCheck from './BirthdayCheck';

const GetAllReviewsProvider = loadable(() => pMinDelay(import("./context/GetAllDataProvider"), 250), {
  fallback: <Splash />,
});
const Home = loadable(() => pMinDelay(import("./Routes/MainComponent"), 250), {
  fallback: <Splash />,
});
const Our_Collections = loadable(() => pMinDelay(import("./Routes/OurCollections"), 250), {
  fallback: <Splash />,
});
const Special_Offers = loadable(() => pMinDelay(import("./Routes/SpecialCollection"), 250), {
  fallback: <Splash />,
});
const About_Page = loadable(() => pMinDelay(import("./Routes/AboutPage"), 250), {
  fallback: <Splash />,
});
const Contact_Us = loadable(() => pMinDelay(import("./Routes/ContactUs"), 250), {
  fallback: <Splash />,
});
const Register_Form = loadable(() => pMinDelay(import("./Auth/Register/Register"), 250), {
  fallback: <Splash />,
});
const Login_Form = loadable(() => pMinDelay(import("./Auth/Login/Login"), 250), {
  fallback: <Splash />,
});
const User_Cart = loadable(() => pMinDelay(import("./component/Cart/Main"), 250), {
  fallback: <Splash />,
});
const User_Profile = loadable(() => pMinDelay(import("./component/Profile/Main"), 250), {
  fallback: <Splash />,
});
const Menus = loadable(() => pMinDelay(import("./component/Menu/Main"), 250), {
  fallback: <Splash />,
});
const Franchise = loadable(() => pMinDelay(import("./Routes/FranchisPage"), 250), {
  fallback: <Splash />,
});
const Allergies = loadable(() => pMinDelay(import("./component/Allergies/Main"), 250), {
  fallback: <Splash />,
});
const Jobs = loadable(() => pMinDelay(import("./component/Jobs/Main"), 250), {
  fallback: <Splash />,
});
const FAQ = loadable(() => pMinDelay(import("./component/FAQ/Main"), 250), {
  fallback: <Splash />,
});
const Privacy = loadable(() => pMinDelay(import("./component/Privacy/Main"), 250), {
  fallback: <Splash />,
});
const Search = loadable(() => pMinDelay(import("./component/search/Main"), 250), {
  fallback: <Splash />,
});
function App() {
  return (
    <CartProvider>
      <AuthProvider>
        <GetAllReviewsProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/our-collections" element={<Our_Collections />} />
              {/* <Route path="/special-offers" element={<Special_Offers />} /> */}
              <Route path="/about" element={<About_Page />} />
              <Route path="/contact" element={<Contact_Us />} />
              <Route path="/register" element={<Register_Form />} />
              <Route path="/login" element={<Login_Form />} />
              <Route path="/cart" element={<User_Cart />} />
              <Route path="/user-profile" element={<User_Profile />} />
              <Route path="/menu/:category_name" element={<Menus />} />
              <Route path="/franchise-us" element={<Franchise />} />
              <Route path="/allergies" element={<Allergies />} />
              <Route path="/jobs" element={<Jobs />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/privacy-policy" element={<Privacy />} />
              <Route path="/search/:m" element={<Search />} />
              <Route path="/special-offers/:name" element={<Special_Offers />} />
            </Routes>
            <CookieConsentModal />
            <BirthdayCheck/>
            <BottomNav />
          </Router></GetAllReviewsProvider></AuthProvider></CartProvider>
  );
}

export default App;
