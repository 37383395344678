import React, { useEffect, useState,useContext } from 'react';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from './Config'; 
import axios from 'axios';
import { CategoriesContext } from './context/GetAllDataProvider';

function BirthdayCheck() {
    const {settings} = useContext(CategoriesContext);
  const [subscribers, setSubscribers] = useState([]);
  const [isLoader, setIsLoader] = useState(true);

  // Fetch subscribers from Firestore
  useEffect(() => {
    const fetchSubscribers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'subscribers'));
        const subscribersList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSubscribers(subscribersList);
        setIsLoader(false);
      } catch (error) {
        console.error('Error fetching subscribers:', error);
      }
    };

    fetchSubscribers();
  }, []);
  useEffect(() => {
    if (isLoader) {
      console.log("Loading...");
    } else {
      console.log("Finished loading subscribers.");
      const today = new Date();
      const todayStr = `${today.getDate()} ${today.toLocaleString('default', { month: 'long' })}`;
      subscribers.forEach(async (subscriber) => {
        if (subscriber.birthday === todayStr && !subscriber.messageSent) {
            const emailMessage = `<div style="max-width: 600px; margin: 0 auto; background-color: #ffffff; border-radius: 8px; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); padding: 20px; text-align: center;">
            <div style="background-color: #ff6f61; color: #ffffff; padding: 20px; border-radius: 8px 8px 0 0;">
                <h1 style="margin: 0;">Happy Birthday from Kebabilicious!</h1>
            </div>
            <div style="padding: 20px; text-align: left;">
                <h2 style="color: #333;">Dear ${subscriber.email},</h2>
                <p style="font-size: 16px; color: #555;">Wishing you a fabulous birthday filled with joy and delicious moments!</p>
                <div style="background-color: #f0f8ff; border: 1px dashed #ff6f61; padding: 15px; margin: 20px 0; border-radius: 5px;">
                    <h3 style="color: #333;">Special Offer Just for You!</h3>
                    <p style="color: #555;">${settings ? settings.subscriberMessage : 'To celebrate your special day, we have a tasty surprise waiting for you at Kebabilicious. Enjoy a **20% discount** on your next order! Just use the code <strong>BIRTHDAY20</strong> at checkout.'}</p>
                </div>
                <p style="font-size: 16px; color: #555;">Thank you for being a part of our Kebabilicious family! We hope you enjoy your day with lots of yummy food!</p>
            </div>
            <div style="margin-top: 20px; font-size: 14px; color: #666;">
                <p>If you have any questions or need assistance, feel free to <a href="mailto:info@Kebabilicious.com" style="color: #ff6f61; text-decoration: none;">contact us</a>.</p>
            </div>
        </div>
        `;
        try {
            const res = await axios.post('https://kebabilicious.com/checkout/email.php', {
                email: subscriber.email,
                subject: `Happy Birthday from Kebabilicious!`,
                message: emailMessage,
            });  
            console.log("Email sent successfully:", res); 
        } catch (emailError) {
            console.error('Error sending email:', emailError);
           
        }

          console.log(`Happy Birthday to ${subscriber.email}! 🎉 Special offer is waiting for you.`);
          const subscriberRef = doc(db, 'subscribers', subscriber.id);
          await updateDoc(subscriberRef, {
            messageSent: true,
            sentDate: new Date().toISOString(),
          });

          console.log(`Message sent status updated for ${subscriber.email}.`);
        }
      });
    }
  }, [subscribers, isLoader]);

  return null; // This component doesn't need to render anything
}

export default BirthdayCheck;
