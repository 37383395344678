import React, { createContext, useContext, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";
import Splash from "../component/Loader/Splash";
import { auth, db } from "../Config";

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isLogged, setIsLogged] = useState(false);

  // Function to fetch user data based on email
  const fetchUserByEmail = async (email) => {
    try {
      const usersRef = collection(db, "users"); // Reference to users collection
      const q = query(usersRef, where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const userData = querySnapshot.docs[0].data(); // Assuming the first match is the correct user
        setIsLogged(true);
        setCurrentUser(userData); console.log(userData);
      } else {
        console.log("No user found with that email.");
        setIsLogged(false);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setIsLogged(false);
    }
  };

  // Check auth state change and fetch user data
  const checkAuthState = async () => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log(user);
        await fetchUserByEmail(user.email); // Fetch user by email
      } else {
        setCurrentUser(null);
        setIsLogged(false);
      }
      setLoading(false);
    });

    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribe = checkAuthState();

    // Clean up subscription on unmount
    return () => unsubscribe;
  }, []);

  const value = {
    currentUser,
    isLogged,
  };

  if (loading) {
    return <Splash />;
  }

  return (
    <AuthContext.Provider value={{currentUser,isLogged,checkAuthState}}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
